@charset "utf-8";
/* CSS Document */

/* 基本設定-------------------------------------------*/
@import "import.scss";

a {
  @include linkBase;
  outline:none;
}
a:hover {
  @include hoverBase;
}
a img,
a svg {
  @include linkBase;
  outline:none;
}

div {
  outline:none;
  word-break: break-word;
}

img {
  max-width: 100%;
  height: auto;
  outline:none;
}

svg {
  backface-visibility: hidden;
  transform: translateZ(0);
}

div,li,header,footer,dt,dd,section {
  box-sizing: border-box;
}

input,
button,
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: transparent;
  border: none;
  border-radius: 0;
  font: inherit;
  outline: none;
}

textarea {
  resize: vertical;
}

input[type='checkbox'],
input[type='radio'] {
  display: none;
}

input[type='submit'],
input[type='button'],
label,
button,
select {
  cursor: pointer;
}

select::-ms-expand {
    display: none;
}

.pc-view {
  display: none !important;
}
.sp-view {
  display: block !important;
}

@media screen and (min-width:769px){
  .pc-view {
    display: block !important;
  }
  .sp-view {
    display: none !important;
  }
}

/*-- Base --*/
html {
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}
body {
  color: $basefontColor;
  background: #FFF;
  line-height: 1.8;
  @include baseFont;
  font-size: 12px;
  width: 100%;
  word-wrap: break-word;
  letter-spacing: 0.02em;
  // font-feature-settings: "palt";
  padding-top: 54px;
}
@media screen and (min-width:769px){
  body {
    font-size: 16px;
    padding-top: 88px;
  }
  a[href*="tel:"] {
    // pointer-events: none;
    // cursor: default;
    text-decoration: none;
  }
}

/*-- HTML5 elements --*/
article, aside, details, figcaption, figure, footer, header, nav, section, summary {
  display: block;
  margin: 0;
  padding: 0;
}
.clearfix:after {
  content: ".";
  display: block;
  clear: both;
  height: 0;
  visibility: hidden;
}

.clearfix {
  min-height: 1px;
}

* html .clearfix {
  height: 1px;
  /*\*//*/
  height: auto;
  overflow: hidden;
  /**/
}

strong {
  font-weight: bold;
}

section,article {
  position: relative;
}

::selection {
  background: #9c9c9c;
  color: #fff;
}

::-moz-selection {
  background: #9c9c9c;
  color: #fff;
}


/* 細かいパーツ-------------------------------------------*/
#page {
  position: relative;
  padding-top: 61px;
}
@media screen and (min-width:769px){
  #page {
    padding-top: 102px;
  }
}

.flex-wrapper {
  display: flex;
}

.box-inner {
  margin: 0 15px;
  position: relative;
}

@media screen and (min-width:769px){
  .box-inner {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 1020px;
  }
  .pc-flex {
    display: flex;
  }
}

//英語フォント
.font-en {
  @include fontLatin;
}

.icon-svg {
  position: absolute;
  width: 0;
  height: 0;
}

// ボタン全般
.btn {
  border: 1px solid $pointColor;
  font-size: 14px;
  position: relative;
  text-align: center;
  letter-spacing: 0.1em;
  img {
    vertical-align: middle;
  }
  a {
    display: block;
    padding: 13px 10px 15px;
    color: $pointColor;
  }
  &.bg01 {
    a {
      background: $pointColor;
      color: #fff;
    }
  }
}
.btn-detail {
  background: #fff;
}
@media screen and (min-width:769px){
  .btn {
    max-width: 465px;
    margin: 0 auto;
    font-size: 18px;
    a {
      padding: 12px 10px;
    }
  }
}
//一覧を見るボタン
.btn-more {
  @include fontLatin;
  @include fontMedium;
  font-size: 18px;
  a {
    padding: 10px 10px 12px;
  }
}
@media screen and (min-width:769px){
  .btn-more {
    width: 276px;
    margin: 0 auto;
  }
}

//矢印アイコン
.icon-arrow01 {
  position: relative;
  width: 12px;
  height: 12px;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: all 0.3s ease;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 8px;
    height: 8px;
    border-top: 2px solid $mainBlkColor;
    border-right: 2px solid $mainBlkColor;
    transform: rotate(45deg);
    transition: all 0.3s ease;
  }
}
@media screen and (min-width:769px){
  .icon-arrow01 {
  }
}

.icon-arrow02 {
  position: relative;
  &::before {
    content: "";
    display: inline-block;
    width: 15px;
    height: 4px;
    border-right: 1px solid $mainBlkColor;
    border-bottom: 1px solid $mainBlkColor;
    transform: skewX(45deg);
    transition: all 0.3s ease;
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    left: 3px;
  }
}

// 新規タブで開くアイコン
.icon-nw {
  position: relative;
  top: 0;
  bottom: 0;
  margin: auto;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 13px;
    height: 13px;
    background: url(/assets/images/common/icon-nw.svg) no-repeat;
    background-size: contain;
  }
}

//小さいテキスト
.font-s {
  font-size: 10px;
}
@media screen and (min-width:769px){
  .font-s {
    font-size: 12px;
  }
}

.page-bd {
  padding-bottom: 60px;
}
@media screen and (min-width:769px){
  .page-bd {
    padding-bottom: 85px;
  }
}

.bgW {
  background: #fff !important;
}

.mds-ttl {
  font-size: 17px;
  font-weight: bold;
  margin-bottom: 9px;
}
@media screen and (min-width:769px){
  .mds-ttl {
    margin-bottom: 30px;
    font-size: 26px;
  }
}

.noinfo {
  text-align: center;
  padding: 20px 0;
}

.tel-pcolor {
  a {
    color: #017A8A;
  }
}
.contents-tbl {
  .tel {
    a {
      color: #333;
    }
  }
}


/* 共通ヘッダー-------------------------------------------*/
.cmn-hd {
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  background: #fff;
  z-index: 98;
  border-bottom: 1px solid $lineColor;
  .hd-inner {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 54px;
  }
  .sclogo {
    margin-left: 15px;
    img {
      width: 94px;
      vertical-align: initial;
    }
  }
  .ttl {
    max-width: 116px;
    img {
      vertical-align: initial;
    }
  }
  .flex-wrapper {
    align-items:flex-end;
    flex-wrap: wrap;
  }

}
.navbtn {
  width: 55px;
  height: 54px;
  position: relative;
  text-align: center;
  font-weight: bold;
  font-size: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
  p {
    z-index: 1;
    position: relative;
  }
  span {
    position: absolute;
    left: 0;
    right: 0;
    margin: 0 auto;
    height: 2px;
    width: 22px;
    background-color: $mainBlkColor;
    display: inline-block;
    box-sizing: border-box;
    transition: all 0.3s ease;
    z-index: 1;
    &:nth-of-type(1) {
      top: 8px;
    }
    &:nth-of-type(2) {
      top: 16px;
    }
    &:nth-of-type(3) {
      top: 24px;
    }
  }
  &.show {
    background-color: $mainBlkColor;
    color: #fff;
    span {
      opacity: 1;
      background-color: #fff;
      &:nth-child(1) {
        transform: translateY(11px) rotate(-45deg);
      }
      &:nth-child(2) {
        opacity: 0;
      }
      &:nth-child(3) {
        transform: translateY(-5px) rotate(45deg);
      }
    }
    &:after {
      display: block;
      content: "";
      width: 100%;
      height: 100vh;
      background: rgba(0,0,0,0.3);
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
    }
  }
}
.btn-lang {
  @include fontLatin;
  padding-top: 10px;
  span {
    opacity: 0;
  }
  img {
    width: 18px;
  }
}
.btn-gnav {
  p {
    position: absolute;
    bottom: 8px;
    left: 0;
    right: 0;
  }
}

@media screen and (min-width:769px){
  .cmn-hd {
    .hd-inner {
      align-items: center;
      height: 88px;
    }
    .sclogo {
      margin-top: 0;
      margin-left: 22px;
      img {
        width: auto;
      }
    }
    .ttl {
      width: auto;
      max-width: initial;
    }
  }
  .navbtn {
    width: 88px;
    height: 87px;
    span {
      width: 27px;
      &:nth-of-type(1) {
        top: 18px;
      }
      &:nth-of-type(2) {
        top: 28px;
      }
      &:nth-of-type(3) {
        top: 38px;
      }
    }
    &.show {
      span {
        &:nth-child(3) {
          transform: translateY(-9px) rotate(45deg);
        }
      }
    }
  }
  .btn-lang {
    font-size: 14px;
    padding-top: 21px;
    img {
      width: 24px;
    }
  }
  .btn-gnav {
    p {
      font-size: 14px;
      bottom: 16px;
    }
  }
}


/* グローバルナビ-------------------------------------------*/
body,html {
  &.fixed {
    overflow: hidden;
  }
}
.nav-contents {
  display: none;
  position: fixed;
  top: 54px;
  left: 0;
  width: 100%;
  background: $mainBlkColor;
  color: #ffffff;
  height: 100vh;
  z-index: 100;
  .inner {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    padding-bottom: 190px;
  }
  a {
    color: #ffffff;
  }
  ul {
    padding: 0 18px 30px;
    li {
      font-size: 14px;
      a {
        display: block;
        padding: 16px 0 16px 5px;
        border-bottom: 1px solid $lineColor;
        position: relative;
      }
      .icon-arrow01 {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        margin: auto;
        &::before {
          border-color: #ffffff;
        }
      }
    }
  }
  .btn {
    margin: 0 18px;
    border-color: #ffffff;
  }
}
@media screen and (min-width:769px){
  .nav-contents {
    top: 88px;
    height: auto;
    ul {
      max-width: 1010px;
      margin: 0 auto;
      padding: 100px 10px 120px;
      display: flex;
      flex-wrap: wrap;
      li {
        font-size: 18px;
        width: calc(100% / 4);
        padding-left: 30px;
        &:nth-child(4n+1) {
          padding-left: 0;
        }
        a {
          border-width: 0;
          padding: 20px 0 20px 5px;
        }
        .icon-arrow01 {
          right: 10px;
          &::before {
            border-width: 1px;
            width: 5px;
            height: 5px;
          }
        }
      }
    }
    .btn {
      width: 276px;
      margin: 0 auto;
    }
  }
}

#gnav {
  .gnav {
    li {
      img {
        width: 4%;
        vertical-align: middle;
        padding-right: 13px;
      }
    }
  }
  .nav01 {
    padding-bottom: 0;
  }
  .nav02 {
    li:first-child {
      border-top: 1px solid $lineColor;
    }
  }
}
@media screen and (min-width:769px){
  #gnav {
    .gnav {
      li {
        img {
          width: 8%;
        }
      }
      .nav-styling {
        img {
          vertical-align: top;
          padding-top: 6px;
          width: 6%;
          padding-right: calc(13px + 2%);
        }
        span {
          display: inline-block;
          line-height: 1.44444444;
        }
      }
    }
    .nav01 {
      padding-bottom: 35px;
    }
    .nav02 {
      border-top: 1px solid $lineColor;
      padding: 30px 0 90px;
      li:first-child {
        border-top: none;
        width: 270px;
      }
      li:nth-child(2) {
        padding-left: 60px;
        .icon-arrow01 {
          right: 50px;
        }
      }
    }
  }
  #langnav {
    .langnav {
      justify-content: space-between;
      li {
        width: 104px;
        padding-left: 0;
        .icon-arrow01 {
          right: -5px;
        }
      }
    }
  }
}

/* アイコンが並ぶメニュー-------------------------------------------*/
.iconnav-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(100% / 4 - 9px);
    margin-right: 12px;
    margin-bottom: 20px;
    font-size: 10px;
    text-align: center;
    font-feature-settings: "palt";
    &:nth-child(1) {
      p {
        width: 110%;
      }
    }
    &:nth-child(4n) {
      margin-right: 0;
    }
    // img {
    //   width: 48px;
    // }
    a {
      display: block;
    }
    svg {
      width: 48px;
      height: 48px;
      stroke: $mainBlkColor;
      --waku-color:#ccc;
    }
  }
}
@media screen and (min-width:769px){
  .iconnav-list {
    flex-wrap: nowrap;
    li {
      margin-right: 20px;
      font-size: 14px;
      &:nth-child(4n) {
        margin-right: 20px;
      }
      &:last-child {
        margin-right: 0;
      }
      // img {
      //   max-width: 86px;
      //   width: 100%;
      // }
      svg {
        max-width: 86px;
        width: 100%;
        height: auto;
      }
      a:hover {
        opacity: 1;
        color: $pointColor;
        svg {
          stroke: $pointColor;
          fill: $pointColor;
          --waku-color:#017A8A;
        }
      }
      p {
        margin-top: 10px;
      }
    }
  }
}

/* 下層タイトル-------------------------------------------*/
.ttl-area {
  background: $mainBlkColor;
  padding: 25px 0;
}
.ttl-mds {
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  font-size: 25px;
  @include fontLatin;
  letter-spacing: 0.05em;
  line-height: 1.5;
  .ttl-jp {
    font-size: 10px;
    font-weight: normal;
    display: block;
  }
}
@media screen and (min-width:769px){
  .ttl-area {
    padding: 38px 0;
  }
  .ttl-mds {
    font-size: 44px;
    .ttl-jp {
      font-size: 14px;
    }
  }
}

/* 下層小見出し-------------------------------------------*/
.submds {
  position: relative;
  font-weight: bold;
  font-size: 17px;
  padding-bottom: 10px;
  margin-bottom: 15px;
  border-bottom: 1px solid $pointColor;
  letter-spacing: 0.1em;
}
@media screen and (min-width:769px){
  .submds {
    font-size: 26px;
    margin-bottom: 20px;
    padding-bottom: 15px;
  }
}

/* パンくず-------------------------------------------*/
.breadcrumb-area {
  background: $bg2Color;
  display: block;
  margin: 0 auto;
  padding: 8px 0;
  border-bottom: 1px solid #898888;
  width: 100%;
  .breadcrumb {
    margin: 0 15px;
    font-size: 10px;
    a {
      text-decoration: underline;
    }
    li {
      position: relative;
      padding-right: 22px;
      display: inline;
      &:after {
        position: absolute;
        content: "";
        vertical-align: middle;
        transform: rotate(45deg);
        border-top: 1px solid #898888;
        border-right: 1px solid #898888;
        left: auto;
        width: 5px;
        height: 5px;
        right: 7px;
        bottom: 0;
        top: 0;
        margin: auto;
        text-decoration: none;
      }
      &:last-child {
        &:after {
          content: none;
        }
        a {
          text-decoration: none;
        }
      }
    }
  }
  .current {
    a {
      text-decoration: none;
    }
  }
}
@media screen and (min-width:769px){
  .breadcrumb-area {
    padding: 15px 0;
    .breadcrumb {
      font-size: 14px;
      margin: 0 auto;
      padding: 0 10px;
      max-width: 1020px;
    }
  }
}

/* コンテンツエリア-------------------------------------------*/
.main-area {
  padding: 25px 0 0;
}
@media screen and (min-width:769px){
  .main-area {
    padding-top: 78px;
  }
}

/* 検索エリア-------------------------------------------*/
.tool-box {
  .main-mds {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 15px;
  }
  .tool {
    margin-bottom: 30px;
  }
  .name {
    margin-bottom: 44px;
  }
}
@media screen and (min-width: 769px) {
  .tool-box {
    display: flex;
    justify-content: space-between;
    margin-bottom: 60px;
    .main-mds {
      margin-bottom: 18px;
      font-size: 16px;
    }
    .tool {
      margin-bottom: 0;
      width: 100%;
      &:nth-child(1) {
        width: 66%;
      }
      &:nth-child(3) {
        width: 55%;
      }
    }
    .selector-wrapper {
      max-width: 250px;
      width: 100%;
      margin-bottom: 0;
      margin-left: 0;
    }
    .keyword {
      max-width: 400px;
      width: 100%;
    }
    .name {
      max-width: 250px;
      width: 100%;
      margin-bottom: 0;
      font-size: 14px;
      margin-right: 0;
    }
  }
}

/* セレクトボックス-------------------------------------------*/
.selector-wrapper {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  margin: 0 auto 27px;
  &:before {
    content: "";
    position: absolute;
    top: 0;
    bottom: 8px;
    right: 17px;
    margin: auto;
    left: auto;
    width: 8px;
    height: 8px;
    border-top: 2px solid $mainBlkColor;
    border-right: 2px solid $mainBlkColor;
    -webkit-transform: rotate(135deg);
    transform: rotate(135deg);
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    right: 46px;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 1px;
    height: 65%;
    background: $lineColor;
  }
  select {
    background: #FFF;
    color: #000;
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    outline: none;
    border: none;
    border: 1px solid $lineColor;
    padding: 20px 0 20px 15px;
    width: 100%;
    letter-spacing: 0.05em;
    font-size: 16px;
  }
  .selector {

  }
}
@media screen and (min-width:769px){
  .selector-wrapper {
    &:before {
      top: -4px;
      bottom: 0;
    }
    select {
      padding: 12px 50px 12px 18px;
      width: 100%;
    }
  }
}
/* 検索フォーム-------------------------------------------*/
.keyword {
  form {
    display: flex;
    border: 1px solid $lineColor;
    padding: 8px 0 8px 15px;
    input[type="text"] {
      outline: none;
      border: none;
      background: #fff;
      height: 36px;
      width: 100%;
      font-size: 16px;
      &::placeholder {
        color: #C9C9C9;
      }
    }
    .search-submit {
      width: 56px;
      border: none;
      transition: all 0.3s;
      background: #fff;
      cursor: pointer;
      background: url('/assets/images/common/icon-search.svg') no-repeat center center;
      background-size: 22px 22px;
      border-left: 1px solid $lineColor;
    }
  }
}
@media screen and (min-width:769px){
  .keyword {
    form {
      max-width: 400px;
      input[type="text"] {
      }
      .search-submit {
        &:hover {
          opacity: 0.6;
        }
      }
    }
  }
}

/* お知らせ共通パーツ-------------------------------------------*/
.info-area {
  .info-list {
    dt {
      color: $pointColor;
      margin-bottom: 2px;
      font-size: 10px;
    }
    dd {
      margin-bottom: 20px;
      a {
        text-decoration: underline;
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .info-area {
    .info-list {
      margin-bottom: 10px;
      display: flex;
      flex-wrap: wrap;
      dt {
        margin-bottom: 22px;
        font-size: 14px;
        // width: 15%;
        padding-right: 5px;
      }
      dd {
        margin-bottom: 22px;
        width: 82%;
        a {
          text-decoration: underline;
        }
      }
    }
  }
}

/* カテゴリ一覧パーツ-------------------------------------------*/
.category-wrapper {
  margin-left: 15px;
  padding-bottom: 25px;
  overflow-x: scroll;
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  margin-bottom: 20px;
  .category-list {
    display: flex;
    flex-wrap: wrap;
    width: 1220px;
    label {
      cursor: pointer;
    }
    li {
      padding-right: 10px;
      font-size: 11px;
      text-align: center;
      flex: 1;
      width: 122px;
      a,label {
        padding: 3px 0;
        display: block;
        border-bottom: 1px solid $lineColor;
        position: relative;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      &.current {
        a,label {
          color: $pointColor;
          border-color: $pointColor;
          &::before {
            content: "";
            width: 100%;
            height: 2px;
            position: absolute;
            bottom: 0;
            left: 0;
            background: $pointColor;
          }
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .category-wrapper {
    margin: 0 auto;
    padding: 0 10px 25px;
    max-width: 1020px;
    overflow-x: auto;
    .category-list {
      width: auto;
      li {
        padding-right: 20px;
        font-size: 16px;
        flex: initial;
        max-width: 200px;
        width: calc(100% / 5);
        margin-bottom: 15px;
        display: flex;
        flex-direction: column;
        &.font-s {
          font-size: 12px;
        } 
        &:nth-child(5n) {
          padding-right: 0;
        }
        a,label {
          padding: 12px 0;
          &:hover {
            color: $pointColor;
            border-color: $pointColor;
            opacity: 1;
            &::before {
              content: "";
              width: 100%;
              height: 2px;
              position: absolute;
              bottom: 0;
              left: 0;
              background: $pointColor;
            }
          }
        }
      }
    }
  }
}

/* ショップ詳細用パーツ-------------------------------------------*/
.shopinfo-area {
  background: #f5f5f5;
  padding-bottom: 30px;
  .info-txt01-weapper {
    margin-bottom: 25px;
  }
  .info-txt01 {
    border-left: 3px solid $mainBlkColor;
    padding: 0 0 0 20px;
    .floor {
      margin-bottom: 3px;
      font-size: 14px;
      font-weight: bold;
      display: flex;
      vertical-align: middle;
    }
    .category {
      color: #898888;
    }
    .shopname {
      font-weight: bold;
      font-size: 20px;
    }
  }
  .info-txt02 {
    .shopimg {
      margin-bottom: 15px;
    }
    .slick-slide {
      img {
        width: 100%;
      }
    }
    .shopimg-wrapper {
      display: none;
    }
    .thumbnail-wrapper {
      display: none;
      margin: 0 -5px;
      .slick-track, .slick-list {
        transform: translate3d(0, 0, 0) !important;
      }
      .thumbnail {
        padding: 5px;
        cursor: pointer;
        transition: all 0.3s ease;
      }
      .slick-current {
        img {
          box-shadow: 0px 0px 0px 2px #ccc;
        }
      }
    }
  }
  .detail-txt {
    margin-bottom: 25px;
  }
  .tel-txt {
    color: $pointColor;
    font-size: 15px;
    padding: 15px 0;
    text-align: center;
    border-top: 1px solid $pointColor;
    border-bottom: 1px solid $pointColor;
  }
}

@media screen and (min-width: 769px) {
  .shopinfo-area {
    padding-top: 55px;
    padding-bottom: 50px;
    a {
      display: block;
    }
    .shopinfo-inner {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
      margin-bottom: 30px;
    }
    .info-txt01-weapper {
      margin-bottom: 0;
      display: flex;
      flex-direction: column;
      flex: 1;
      .btn-detail {
        max-width: 350px;
        width: 100%;
        margin-left: 0;
        margin-top: auto;
      }
    }
    .info-txt01 {
      border-left-width: 2px;
      padding: 0 0 0 25px;
      margin-bottom: 30px;
      .floor {
      }
      .category {
        font-size: 14px;
      }
      .shopname {
        font-size: 28px;
      }
    }
    .detail-txt {
      margin-bottom: 0;
      font-size: 14px;
    }
    .tel-txt {
      font-size: 16px;
    }
    .info-txt02-weapper {
      margin-right: 50px;
    }
    .info-txt02 {
      min-width: 400px;
      .shopimg {
        width: 400px;
        margin-bottom: 0;
      }
      .shopimg-wrapper {
        width: 400px;
      }
      .thumbnail-wrapper {
        width: 400px;
        margin: 0;
        .thumbnail {
          max-width: 134px;
          &:hover {
            opacity: 0.7;
          }
        }
      }
    }
  }
}

.shopinfo2 {
  padding: 33px 0 40px;
  margin-top: 44px;
  .mds {
    text-align: center;
    margin-bottom: 20px;
    font-weight: bold;
    font-size: 20px;
  }
  .shopinfo-tbl {
    font-size: 14px;
    background: #fff;
    margin-bottom: 30px;
    width: 100%;
    a {
      text-decoration: underline;
    }
    th,td {
      vertical-align: middle;
      padding: 11px;
    }
    th {
      border: 2px solid #ffffff;
      background: #f5f5f5;
      text-align: center;
      width: 30%;
    }
    td {
      border: 2px solid #f5f5f5;
    }
    .shopname {
      font-weight: bold;
      font-size: 15px;
    }
  }
}
.btn-tbl {
  border: 1px solid $mainBlkColor;
  font-size: 12px;
  @include fontLatin;
  letter-spacing: 0.1em;
  width: 109px;
  box-sizing: border-box;
  margin-top: 5px;
  &.detail {
    width: 119px;
    a {
      padding-left: 10px;
    }
  }
  a {
    display: block;
    padding: 0 0 0 18px;
    text-decoration: none !important;
  }
}
@media screen and (min-width: 769px) {
  .shopinfo2 {
    margin-top: 85px;
    padding: 50px 0 55px;
    .mds {
      font-size: 30px;
      margin-bottom: 35px;
    }
    .shopinfo-tbl {
      margin-bottom: 40px;
      .pc-flex {
        justify-content: space-between;
      }
    }
  }
  .btn-tbl {
    margin-top: 0;
    margin-right: 30px;
    border: none;
    padding: 0;
    width: auto;
    &.detail {
      width: 120px;
      margin-right: 0;
      margin-left: auto;
      font-size: 14px;
      .icon-arrow02::before {
        width: 113px;
        top: 24px;
        left: -104px;
      }
    }
    a {
      display: inline;
      padding: 0;
    }
    .icon-arrow02::before {
      width: 86px;
      height: 6px;
      top: 16px;
      left: -76px;
    }
  }
}

/* ブログ・ニュース＆イベント・フード・スタイリング一覧共通パーツ-------------------------------------------*/
.article-list {
  display: flex;
  flex-wrap: wrap;
  li {
    width: calc(100% / 2 - 10px);
    margin-right: 20px;
    margin-bottom: 30px;
    &:nth-child(2n) {
      margin-right: 0;
    }
    .img-area {
      position: relative;
      margin-bottom: 10px;
      background: #ffffff;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      // height: 44vw;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        //max-width: 100%;
        //max-height: 100%;
        width: 100%;
        height: 100%;
        border: none !important;
        object-fit: cover;
      }
      .icon-new {
        position: absolute;
        top: 0;
        left: 0;
        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 45px 45px 0 0;
          border-color:$mainBlkColor transparent transparent transparent;
        }
        span {
          color: #ffffff;
          z-index: 1;
          position: relative;
          font-size: 10px;
          transform: rotate(-45deg);
          display: block;
          right: -2px;
          top: 7px;
        }
      }
      .icon-category {
        position: absolute;
        right: 0;
        bottom: 0;
        background: #ffffff;
        font-size: 10px;
        color: $lineColor;
        padding: 2px 8px;
      }
      .filter-more {
        transition: all 0.3s ease;
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        width: 100%;
        height: 100%;
        background: rgba(1,122,138,0.6);
        color: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
        @include fontLatin;
        @include fontMedium;
        font-size: 16px;
        span {
          border: 1px solid #fff;
          padding: 15px;
          width: 70%;
          text-align: center;
        }
      }
    }
    .date {
      color: $pointColor;
      font-size: 10px;
    }
    .date-wrapper {
      display: flex;
      .date {
        margin-right: auto;
      }
      .floor {
        background: #ffffff;
        font-size: 10px;
        color: #898888;
        padding: 2px 5px;
      }
    }
    .title {
      font-weight: bold;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      overflow: hidden;
      margin-bottom: 5px;
      line-height: 1.6;
    }
    .shopname {
      font-size: 10px;
    }
  }
  &.blog {
    li {
      margin-bottom: 25px;
      .img-area {
        .icon-category {
          color: $pointColor;
        }
      }
    }
    .title {
      -webkit-line-clamp: 2;
    }
  }
}
.newslist {
  li {
    .img-area {
      position: relative;
      overflow: hidden;
      display: flex !important;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: auto;
      &:before {
        content: "";
        display: block;
        padding-top: 100%;
      }
      img {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        max-width: 100%;
        width: auto;
        max-height: 100%;
        height: auto;
        border: none !important;
      }
    }
  }
}
.foodlist,
.stylinglist {
  li {
    .img-area {
      aspect-ratio: 235/313;
      height: auto;
      .icon-new span {
        letter-spacing: 0.02em;
        line-height: 1.8;
      }
    }
    .detail {
      font-size: 12px;
      letter-spacing: 0.14em;
      line-height: 1.33333333;
      .shop,.item,.price,.scene {
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        word-break: break-all;
      }
      .shop {
        font-size: 12px;
        color: #949494;
        font-weight: 500;
      }
      p {
        margin-top: 6px;
        .sub {
            color: #949494;
            padding-right: 5px;
        }
      }
      .nickname,
      .item {
        font-weight: 500;
      }
      .nickname,
      .scene {
        -webkit-line-clamp: 1;
      }
      .price {
          line-height: 1.5;
      }
      .like {
          display: flex;
          align-items: center;
          .icon {
              display: inline-block;
              margin-right: 5px;
              width: 15px;
              height: 15px;
              background: url(../images/common/icon_heart-off.svg) bottom/100% no-repeat;
          }
          &.on {
              .icon {
                  background: url(../images/common/icon_heart-on.svg) bottom/100% no-repeat;
              }
          }
      }
    }
  }
}
@media screen and (min-width:769px){
  .article-list {
    li {
      width: calc(100% / 4 - 18px);
      margin-right: 24px;
      margin-bottom: 40px;
      &:nth-child(2n) {
        margin-right: 24px;
      }
      &:nth-child(4n) {
        margin-right: 0;
      }
      a {
        display: block;
        &:hover {
          opacity: 1;
          .filter-more {
            opacity: 1;
            display: flex;
          }
        }
      }
      .img-area {
        margin-bottom: 10px;
        max-width: 232px;
        max-height: 232px;
        height: 22.5vw;
        .icon-new {
          position: absolute;
          top: 0;
          left: 0;
          &::after {
            border-width: 70px 70px 0 0;
          }
          span {
            font-size: 14px;
            right: -6px;
            top: 10px;
          }
        }
        .icon-category {
          font-size: 14px;
        }
      }
      .date {
        font-size: 14px;
        min-width: 77px;
      }
      .date-wrapper {
        display: flex;
        .floor {
          font-size: 14px;
        }
      }
      .title {
        -webkit-line-clamp: 2;
      }
      .shopname {
        font-size: 14px;
      }
    }
    &.blog {
      li {
        margin-bottom: 65px;
      }
    }
  }
  .newslist {
    li {
      .img-area {
        max-width: initial;
        max-height: initial;
        height: auto;
      }
    }
  }
  .foodlist,
  .stylinglist {
    li {
      .img-area {
        aspect-ratio: 235/313;
        height: auto;
        max-height: none;
      }
      .detail {
        .shop {
          font-size: 14px;
          line-height: 1.35714286;
        }
        .nickname,
        .item {
          font-size: 14px;
        }
      }
    }
  }
}


/* WYSIWYG-------------------------------------------*/
.blog-detail-box {
  .category {
    font-size: 14px;
    color: #898888;
    margin-bottom: 10px;
  }
  .date {
    color: $pointColor;
    margin-bottom: 10px;
  }
  .ttl {
    font-weight: bold;
    padding-bottom: 15px;
    margin-bottom: 30px;
    border-bottom: 1px solid $mainBlkColor;
    font-size: 15px;
  }
  .url {
    margin-bottom: 5px;
    a {
      text-decoration: underline;
    }
  }
  .main-txt-box {
    margin-bottom: 30px;
    padding: 0 0 20px;
    border-bottom: 1px solid $lineColor;
  }
}
.main-txt-box {
  p {
    margin: 0 0 5px;
  }
  a {
    text-decoration: underline;
  }
  strong,b {
    font-weight: bold;
  }
  em {
    font-style: italic;
  }
  img {
    max-width: 100%;
    margin: 0 auto;
    display: block;
    max-height: 600px;
    width: auto;
    height: auto;
  }
  ul {
    list-style-type: disc;
  }
  ol {
    list-style-type: decimal;
  }
  table {
    th {
      text-align: center;
      vertical-align: inherit;
      font-weight: bold;
    }
  }
  .fwN, caption, h1, h2, h3, h4, h5, h6, td, th {
    margin: 0;
  }
  h1 {
    font-size: 2em;
  }
  h2 {
    font-size: 1.5em;
  }
  h3 {
    font-size: 1.17em;
  }
  h4 {
    font-size: 1em;
  }
  h5 {
    font-size: 0.83em;
  }
  h6 {
    font-size: 0.67em;
  }
  hr {
    display: block;
  }
}
@media screen and (min-width:769px){
  .blog-detail-box {
    .category {
      font-size: 20px;
      margin-bottom: 15px;
    }
    .date {
      font-size: 20px;
      margin-bottom: 20px;
    }
    .ttl {
      font-size: 26px;
      padding-bottom: 40px;
      margin-bottom: 50px;
    }
    .main-txt-box {
      margin-bottom: 50px;
    }
  }
  .main-txt-box {
    .url {
      margin-bottom: 20px;
    }
  }
}

/* ページャー-------------------------------------------*/
.pager-box {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 30px 0 0;
  li {
    position: relative;
    @include fontLatin;
    font-weight: bold;
    font-size: 16px;
    border: 1px solid $pointColor;
    width: 45px;
    height: 45px;
    &.current {
      background: $pointColor;
      color: #ffffff;
      a {
        color: #ffffff;
      }
    }
    a {
      color: $pointColor;
      display: block;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .icon-arrow01 {
      position: absolute;
      &::before {
        border-color: $pointColor;
        width: 12px;
        height: 12px;
      }
    }
  }
  .btn-back {
    border: none;
    width: 35px;
    .icon-arrow01 {
      left: 5px;
      &::before {
        transform: rotate(225deg);
      }
    }
  }
  .btn-next {
    border: none;
    width: 35px;
    .icon-arrow01 {
      right: 5px;
    }
  }
  .disable {
    opacity: 0;
    visibility: hidden;
  }
}
@media screen and (min-width:769px){
  .pager-box {
    max-width: 360px;
    margin: 0 auto;
  }
}

/* 右下のPAGETOPボタン-------------------------------------------*/
.btn-pagetop {
  cursor: pointer;
  transition: 0.3s;
  position: fixed;
  bottom: 5%;
  right: 3%;
  z-index: 9;
  display: block !important;
  opacity: 1;
  width: 50px;
  height: 50px;
  &:hover {
    opacity: 0.7;
  }
}
.btn-hide {
  opacity: 0;
  visibility: hidden;
  transition: all  0.3s;
  &:hover {
    visibility: hidden;
  }
}

/* 静的ページの共通パーツ-------------------------------------------*/
.static-area {
  .mds-fwb {
    font-weight: bold;
    font-size: 14px;
    margin-bottom: 10px;
  }
  .contents-hd {
    margin-bottom: 45px;
    .img-box {
      margin-bottom: 20px;
      text-align: center;
      img {
        max-width: 215px;
      }
    }
    .txt-box {
      text-align: center;
      border-top: 2px solid;
      border-bottom: 2px solid;
      padding: 12px 0;
      font-size: 20px;
      font-weight: bold;
      border-color: #000;
      font-size: 17px;
      margin-bottom: 25px;
    }
    .txt-ttl {
      margin-bottom: 25px;
    }
    .catch01 {
      font-size: 14px;
      text-align: center;
      p {
        margin-bottom: 5px;
      }
    }
    .fukidashi {
      color: #fff;
      background: #000;
      position: relative;
      padding: 16px 10px;
      margin-right: 10%;
      &::before {
        content: "";
        position: absolute;
        margin: auto;
        right: -9px;
        top: 0;
        bottom: 0;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 0 5px 9px;
        border-color: transparent transparent transparent #000;
      }
      .txt01 {
        font-size: 11px;
        font-weight: normal;
      }
      .txt02 {
        font-size: 17px;
      }
    }
  }
  .c-box {
    margin-bottom: 45px;
    &:last-child {
      margin-bottom: 0;
    }
    p {
      margin-bottom: 15px;
    }
    .submds-s {
      margin-bottom: 0;
    }
    .icon-tel {
      position: relative;
      margin-right: 25px;
      &::before {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        width: 16px;
        height: 16px;
        background: url(/assets/images/common/icon-tel.png) no-repeat;
        background-size: contain;
      }
    }
  }
  .tokuten-list {
    margin: 30px 0 35px;
    li {
      position: relative;
      padding: 43px 15px 18px;
      margin-bottom: 20px;
      font-size: 14px;
      .mds {
        color: #fff;
        font-size: 13px;
        font-weight: bold;
        text-align: center;
        position: absolute;
        top: -10px;
        left: 0;
        padding: 2px 20px;
        span {
          @include fontLatin;
          padding-left: 5px;
          font-size: 16px;
        }
      }
      p {
        margin-bottom: 0;
      }
      .note {
        font-size: 11px;
        margin-top: 5px;
      }
    }
    &.list-l {
      li {
        .mds {
          font-size: 17px;
        }
      }
    }
  }
  .tokuten-txt-box {
    border: 1px solid;
    font-size: 14px;
    padding: 10px 15px;
    h4 {
      margin-top: -40px;
      margin-bottom: 10px;
      font-weight: bold;
      text-align: center;
      font-size: 17px;
      span {
        padding: 0 15px;
        background: #fff;
      }
    }
  }
  .info-box {
    border: 1px solid $pointColor;
    padding: 15px 20px 10px;
    margin: 40px auto 30px;
    text-align: center;
    .mds-fwb {
      font-size: 12px;
      margin-bottom: 8px;
    }
  }
  .tel-box {
    li {
      .tel-num {
        font-size: 23px;
        @include fontMedium;
        @include fontLatin;
        letter-spacing: 0.1em;
        margin: 10px 0;
        line-height: 1;
        span {
          font-size: 12px;
          font-weight: normal;
        }
      }
    }
  }
}
@media screen and (min-width: 769px) {
  .static-area {
    .font-s {font-size: 14px;}
    .mds-fwb {
      font-size: 18px;
    }
    .contents-hd {
      margin-bottom: 90px;
      .pc-flex {
        align-items: center;
      }
      .img-box {
        img {
        }
      }
      .txt-box {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }
      // .txt-ttl {
      // }
      // .catch01 {
      //   p {
      //   }
      // }
      .fukidashi {
        padding: 20px;
        max-width: 160px;
        &::before {
          content: "";
          right: -9px;
          border-width: 5px 0 5px 9px;
          border-color: transparent transparent transparent #000;
        }
        .txt01 {
          font-size: 14px;
        }
        .txt02 {
          font-size: 22px;
        }
      }
    }
    .c-box {
      margin-bottom: 100px;
      &:last-child {
      }
      p {
      }
      .submds-s {
      }
      .icon-tel {
        margin-right: 40px;
        &::before {
          width: 23px;
          height: 23px;
        }
      }
    }
    .tokuten-list {
      display: flex;
      flex-wrap: wrap;
      li {
        width: calc(100% / 2 - 10px);
        margin-right: 20px;
        margin-bottom: 40px;
        font-size: 18px;
        padding: 35px 30px;
        &:nth-child(2n) {
          margin-right: 0;
        }
        .mds {
          top: -18px;
          font-size: 16px;
          span {
            font-size: 22px;
          }
        }
        .note {
          font-size: 14px;
          margin-top: 12px;
        }
      }
      &.list-l {
        li {
          .mds {
          }
        }
      }
    }
    .tokuten-txt-box {
      font-size: 18px;
      padding: 25px 30px 20px;
      h4 {
        font-size: 26px;
        margin-top: -48px;
        margin-bottom: 25px;
        span {
          padding: 0 45px;
        }
      }
    }
    .info-box {
      .mds-fwb {
      }
    }
    .tel-box {
      li {
        .tel-num {
          font-size: 30px;
          span {
            font-size: 16px;
          }
        }
      }
    }
  }
}

/* 静的ページの共通パーツその2-------------------------------------------*/
.ttl-s {
  padding: 35px 0;
  .ttl-mds {
    font-size: 20px;
  }
}
@media screen and (min-width: 769px) {
  .ttl-s {
    padding: 55px 0;
    .ttl-mds {
      font-size: 34px;
    }
  }
}
.txt-style-box {
  .contents-tbl {
    margin: 15px 0 40px;
    width: 100%;
    a {
      text-decoration: underline;
    }
    th,td {
      border: 2px solid #F5F5F5;
      vertical-align: middle;
    }
    th {
      background: #F5F5F5;
      text-align: center;
      padding: 13px 10px;
      width: 30%;
      border-bottom: 2px solid #fff;
    }
    td {
      padding: 10px;
      .btn {
        margin: 15px 0;
        a {
          text-decoration: none;
        }
      }
    }
  }
  p {
    margin-bottom: 15px;
  }
  .txt-list {
    list-style: decimal;
    li {
      margin-bottom: 15px;
      margin-left: 13px;
    }
  }
  .mds-txt {
    @include fontMedium;
    margin-bottom: 0;
  }
  .indent-txt {
    margin-bottom: 15px;
    padding-left: 10px;
  }
  .c-box {
    margin-bottom: 35px;
  }
}
@media screen and (min-width: 769px) {
  .txt-style-box {
    .contents-tbl {
      margin: 30px 0 90px;
      th,td {
        font-size: 16px;
      }
      th {
        padding: 30px 10px;
      }
      td {
        padding: 22px 20px;
        .pc-flex {
          justify-content: space-between;
          .btn {
            margin-left: 0;
            width: 48.5%;
          }
        }
      }
      .font-s {
        font-size: 14px;
      }
    }
    p {
      margin-bottom: 40px;
    }
    .txt-list {
      li {
        margin-bottom: 40px;
        margin-left: 18px;
      }
    }
    .mds-txt {
      margin-bottom: 0;
    }
    .indent-txt {
      margin-bottom: 40px;
      padding-left: 15px;
    }
    .c-box {
      margin-bottom: 75px;
    }
    .font-s {
      font-size: 14px;
    }
  }
}

/* フッター-------------------------------------------*/
.ft-nav {
  background: $bg2Color;
  padding: 30px 0 10px;
  .btn-top {
    margin-bottom: 30px;
  }
}
.cmn-ft {
  background: $mainBlkColor;
  padding: 25px 0;
  position: relative;
  color: #ffffff;
  a {
    color: #ffffff;
  }
  .icon-arrow01 {
    margin-right: 18px;
    &::before {
      border-color: #ffffff;
      width: 6px;
      height: 6px;
    }
  }
  .ft-box01 {
    margin-bottom: 38px;
    .txtnav {
      margin-bottom: 25px;
      li {
        font-size: 14px;
        font-weight: bold;
        margin-bottom: 25px;
      }
      .txt-ttl {
        margin-bottom: 12px;
      }
      .navtxt-s {
        font-weight: normal;
        margin-bottom: 12px;
      }
    }
  }
  .ft-box02 {
    text-align: center;
    .ft-logo {
      margin-bottom: 12px;
      svg {
        width: 100%;
        fill:#ffffff;
      }
    }
    address {
      margin-bottom: 25px;
    }
  }
  .copyright {
    @include fontLatin;
    @include fontMedium;
    font-size: 10px;
    color: #898888;
  }
}
@media screen and (min-width:769px){
  .ft-nav {
    padding: 60px 0 52px;
    .btn-top {
      margin-bottom: 36px;
    }
  }
  .cmn-ft {
    padding: 50px 0 30px;
    .ft-box01 {
      display: flex;
      justify-content: space-between;
      margin-bottom: 15px;
      .txtnav {
        margin-bottom: 0;
        li {
          font-size: 16px;
        }
        .navtxt-s {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
    }
    .ft-box02 {
      .ft-logo {
        margin-bottom: 15px;
      }
      address {
        margin-bottom: 40px;
      }
    }
    .copyright {
      font-size: 14px;
    }
  }
}
