//フォント設定
@mixin baseFont {
	font-family: "Noto Sans JP", "ヒラギノ角ゴシック", "Hiragino Kaku Gothic", "游ゴシック", YuGothic, "メイリオ", Meiryo,sans-serif;
}
@mixin fontLatin {
	font-family: 'Barlow Semi Condensed', sans-serif;
}
@mixin fontNormal {
	font-weight: 400;
}
@mixin fontMedium {
	font-weight: 500;
}
@mixin fontBold {
	font-weight: 700;
}
// レスポンシブ対応（可変）のフォントサイズ（クライアントによって好みが分かれるので仕様は適宜判断）
@function get_vw($size, $viewport:375){
  $rate: 100 / $viewport;
  @return $rate * $size * 1vw;
}
@mixin fz_vw($font_size:14){
  font-size: $font_size * 1px;
  font-size: get_vw($font_size);
}

// 内側のボックスサイズ
@mixin innerBase {
	max-width: 1160px;
	width: 100%;
	margin: 0 auto;
}

// リンク設定
@mixin linkBase {
	text-decoration: none;
	transition: all  0.3s;
	color: $mainBlkColor;
}
@mixin hoverBase {
	opacity: 0.6;
}
@mixin boxSizing {
	box-sizing: border-box;
}

// ボタン・矢印アイコンベース
@mixin btnBase {
	width: 100%;
	font-weight: bold;
	 a {
	    display: block;
	    border: 2px solid $mainBlkColor;
	    text-align: center;
	    padding: 17px 0;
	    position: relative;
	 }
	 @media screen and (min-width:769px){
	 	a {
	 		padding: 25px 0;
	 	}
	 }
}
@mixin arrowBase {
	position: absolute;
    left: 0;
    content: "";
    vertical-align: middle;
}
@mixin arrow01Base {
    width: 10px;
    height: 10px;
    border-top: 2px solid $mainBlkColor;
    border-right: 2px solid $mainBlkColor;
    transform: rotate(45deg);
}

// フェード設定
@mixin fadeBase {
	opacity: 0;
    transition: all 1.5s;
}
@mixin fadeIn {
	opacity: 1;
}

// キーカラー設定
$basefontColor : #333333;
$mainBlkColor : #333333;
$pointColor : #017A8A;
$lineColor : #cccccc;
$bg2Color:#f5f5f5;
